// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-template-author-post-list-template-js": () => import("./../../../src/template/author_post_list_template.js" /* webpackChunkName: "component---src-template-author-post-list-template-js" */),
  "component---src-template-blog-template-js": () => import("./../../../src/template/blog_template.js" /* webpackChunkName: "component---src-template-blog-template-js" */),
  "component---src-template-category-template-js": () => import("./../../../src/template/category_template.js" /* webpackChunkName: "component---src-template-category-template-js" */),
  "component---src-template-tag-template-js": () => import("./../../../src/template/tag_template.js" /* webpackChunkName: "component---src-template-tag-template-js" */)
}

